/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.image img {
  max-width: 100%;
  height: auto;
}

.image a {
  display: inline-block;
  max-width: 100%;
}

.image .image-caption {
  display: block;
  color: #707070;
  font-size: 1.6em;
}

.image.logo img {
  vertical-align: middle;
}

.image.image-default-size img {
  max-width: none;
  width: auto;
}

.image-full-size img {
  width: 100% !important;
  height: auto !important;
}

.image-left .component-content > div > img {
  float: left;
}

.image-right .component-content > div > img {
  float: right;
}

.logo-grid > div {
  display: inline-block;
}
